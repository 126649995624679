@media (max-width: 580px) {
    .change__product__name__modal {
        &__content {
            width: 400px;
        }
    }
}

@media (max-width: 470px) {
    .change__product__name__modal {
        &__content {
            width: 350px;
        }
    }
}

@media (max-width: 380px) {
    .change__product__name__modal {
        &__content {
            width: 90%;
        }
        &__form {
            &__input {
                width: 100%;
            }
        }
    }
}
.send__sms__modal {
    background-color: rgba($color: #000000, $alpha: .4);
    display: flex;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s;
    &__content {
        position: absolute;
        width: 500px;
        min-height: 400px;
        max-height: 600px;
        padding: 20px;
        background-color: #EDEDED;
        border-radius: 20px;
        opacity: 0;
        overflow: auto;
        transition: all 0.5s;
        transform: translateY(-100%);
        &.show {
            transform: translateY(0);
            opacity: 1;
            transition: all 0.5s;
        }
    }
    &.show {
        opacity: 1;
        pointer-events: all;
        transition: all 0.5s;
        z-index: 10;
    }
    &__close {
        position: absolute;
        right: 8px;
        top: 8px;
        cursor: pointer;
    }
    &__secondary__header {
        margin-top: 10px;
    }
    &__form {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        &__input {
            padding-left: 15px;
            color: #343434;
            font-weight: 300;
            font-size: 20px;
            margin-top: 10px;
            width: 300px;
            height: 50px;
            border-radius: 50px;
            background-color: #EDEDED;
            border: 1px solid #808080;
            box-shadow: inset 2px 2px 5px 0 rgba(0, 0, 0, 0.2), inset -6px -6px 10px 0 rgba(255, 255, 255, 0.5);
            &-text {
                margin-top: 7px;
                width: 90%;
                height: 200px;
                resize: none;
                border-radius: 10px;
            }
        }
        &__file {
            margin-top: 20px;
            color: #000;
        }
        
    }
    &__btn {
        position: sticky;
        left: 325px;
        bottom: 0px;
        margin-top: 20px;
        width: 130px;
        height: 50px;
        border-radius: 7px;
        border: 2px solid rgba($color: #000000, $alpha: .45);
        color: rgba($color: #000000, $alpha: .75);
        font-weight: 500;
        background-color: #EDEDED;
        transition: all 0.3s;
        margin-bottom: 15px;
        &__wrapper {
            background-color: #EDEDED;
            width: 100%;
            height: 50px;
        }
        &:hover {
            content: '';
            box-shadow: inset 2px 2px 5px 0 rgba(0, 0, 0, 0.2), 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
        }
    }
}

@media (max-width: 580px) {
    .send__sms__modal {
        &__content {
            width: 400px;
        }
    }
}

@media (max-width: 470px) {
    .send__sms__modal {
        &__content {
            width: 350px;
        }
    }
}

@media (max-width: 380px) {
    .send__sms__modal {
        &__content {
            width: 90%;
        }
        &__form {
            &__input {
                width: 100%;
            }
        }
    }
}

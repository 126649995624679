
@media (max-width: 768px) {
    .order__item {
        width: 90%;
    }
}

@media (max-width: 449px) {
    .order__item {
        width: 90%;
        &__name {
            font-size: 30px;
        }
        &__sum {
            font-size: 24px;
        }
    }
}

@media (max-width: 400px) {
    .order__item {
        width: 90%;
        &__name {
            font-size: 30px;
        }
        &__delivery {
            font-size: 20px;
        }
        &__address {
            font-size: 20px;
        }
        &__sum {
            font-size: 18px;
        }
        &__time {
            font-size: 16px;
            right: 32px;
        }
        &__date {
            font-size: 16px;
            right: 32px;
        }
    }
}


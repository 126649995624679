.statistic {
    min-height: 100vh;
    padding-top: 145px;
    padding-bottom: 200px;
    &__header {
        color: #fff;
    }
    &__last__month {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #fff;
        font-size: 24px;
    }
    &__menu {
        display: grid;
        padding: 10px 25px;
        grid-template-columns: repeat(4, 220px) 180px;
        width: 1104px;
        margin: 0 auto;
    }
    &__sort {
        &__reset {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            color: #fff;
            font-size: 24px;
            justify-self: start;
        }
        &__orders {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            color: #fff;
            font-size: 24px;
            justify-self: start;
        }
        &__spend {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            color: #fff;
            font-size: 24px;
            justify-self: start;
        }
        &__date {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            color: #fff;
            font-size: 24px;
            justify-self: start;
        }
        &__arrow {
            position: absolute;
            margin-left: 6px;
            right: -13px;
            top: -2px;
            height: 30px;
        }
        &__arrow-reverse {
            position: absolute;
            margin-left: 6px;
            right: -13px;
            top: 6px;
            transform: rotate(180deg);
            height: 30px;
        }
    }

}
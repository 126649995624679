@media (max-width: 1351px) {
    .user__page__order {
        width: 470px;
    }
}

@media (max-width: 959px) {
    .user__page__order {
        width: 335px;
        &__sum {
            font-size: 25px;
        }
    }
}

@media (max-width: 687px) {
    .user__page__order {
        width: 310px;
        &__sum {
            font-size: 25px;
        }
    }
}
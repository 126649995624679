.basket {
    min-height: 100vh;
    padding-top: 120px;
    padding-bottom: 250px;
    &__wrapper {
        margin-top: 60px;
        display: flex;
        justify-content: space-between;
    }
    &__item {
        &__img {
            display: block;
            width: 300px;
        }
        &__quantity__decr {
            cursor: pointer;
            user-select: none;
        }
        &__quantity__incr {
            cursor: pointer;
            user-select: none;
        }
    }
    &__list__clear {
        cursor: pointer;
        text-decoration: underline;
    }
    &__header {
        margin-top: 50px;
        font-size: 48px;
        color: #F4F4F4;
    }
    &__buy {
        &__header {
            color: #fff;
            font-size: 40px;
            margin-top: 40px;
            text-align: center;
        }
        &__divider {
            width: 254px;
            margin: 0 auto;
            height: 1px;
            background-color: #696969;
            margin-top: 5px;
        }
        &__wrapper {
            position: relative;
            width: 316px;
            height: 428px;
            border: 2px solid #696969;
            border-radius: 20px;
            &__child {
                position: absolute;
                bottom: 30px;
                display: flex;
                height: 134px;
                width: 100%;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
            }
        }
        &__button {
            width: 260px;
            height: 46px;
            border: none;
            font-size: 24px;
            border-radius: 30px;
            background-color: #C82A2A;
            color: #fff;
            transition: all 0.5s;
            &:hover {
                background-color: #A02222;
            }
        }
    }
    &__totalprice {
        color: #fff;
        font-size: 40px;
    }
    &__list__clear {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 20px;
        color: #f4f4f4;
        opacity: 0.85;
        bottom: -40px;
        text-align: center;
    }
}
.create__slider__page__modal {
    background-color: rgba($color: #000000, $alpha: .4);
    display: flex;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s;
    &__content {
        position: absolute;
        width: 500px;
        height: 450px;
        padding: 20px;
        background-color: #EDEDED;
        border-radius: 20px;
        opacity: 0;
        transition: all 0.5s;
        transform: translateY(-100%);
        &.show {
            transform: translateY(0);
            opacity: 1;
            transition: all 0.5s;
        }
    }
    &.show {
        opacity: 1;
        pointer-events: all;
        transition: all 0.5s;
        z-index: 10;
    }
    &__close {
        position: absolute;
        right: 8px;
        top: 8px;
        cursor: pointer;
    }
    &__form {
        &__input {
            padding-left: 15px;
            color: #343434;
            font-weight: 300;
            font-size: 20px;
            margin-top: 40px;
            width: 300px;
            height: 50px;
            border-radius: 50px;
            background-color: #EDEDED;
            border: 1px solid #808080;
            box-shadow: inset 2px 2px 5px 0 rgba(0, 0, 0, 0.2), inset -6px -6px 10px 0 rgba(255, 255, 255, 0.5);
        }
        &__file {
            margin-top: 7px;
        }
    }
    &__text {
        margin-top: 5px;
        font-size: 23px;
        font-weight: 400;
        &-second {
            margin-top: 15px;
            font-size: 23px;
            font-weight: 400;
        }
    }
    &__divider {
        height: 2px;
        width: 100%;
        border-radius: 20px;
        background-color: #808080;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    &__btn {
        position: absolute;
        right: 25px;
        bottom: 15px;
        width: 130px;
        height: 50px;
        border-radius: 7px;
        border: 2px solid rgba($color: #000000, $alpha: .45);
        color: rgba($color: #000000, $alpha: .75);
        font-weight: 500;
        background-color: #EDEDED;
        transition: all 0.3s;
        &:hover {
            content: '';
            box-shadow: inset 2px 2px 5px 0 rgba(0, 0, 0, 0.2), 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
        }
    }
}


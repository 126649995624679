@media (max-width: 1351px) {
    .basket {
        &__buy{
            &__wrapper {
                width: 240px;
                height: 355px
            }
            &__divider {
                width: 95%;
            }
            &__button {
                width: 170px;
                height: 50px;
                font-size: 24px;
            }
        }
        &__list {
            &__clear {
                width: 150px;
            }
        }
    }
}

@media (max-width: 959px) {
    .basket {
        padding-top: 40px;
        &__header {
            margin-top: 35px;
        }
        &__wrapper {
            margin-top: 25px;
            flex-direction: column;
        }
        &__buy{
            &__wrapper {
                margin-top: 25px;
                width: 100%;
                height: 355px
            }
            &__divider {
                width: 95%;
            }
            &__button {
                width: 240px;
                height: 60px;
                font-size: 24px;
            }
        }
    }
}

@media (max-width: 687px) {
    .basket {
        padding-top: 40px;
        &__wrapper {
            flex-direction: column;
        }
        &__totalprice {
            font-size: 32px;
            margin-top: 15px;
        }
        &__buy{
            &__wrapper {
                margin-top: 25px;
                width: 100%;
                height: 300px
            }
            &__divider {
                width: 95%;
            }
            &__button {
                width: 210px;
                height: 55px;
                font-size: 24px;
            }
        }
    }
}

@media (max-width: 624px) {
    .basket {
        padding-bottom: 420px;
    }
}
.admin {
    padding-top: 130px;
    min-height: 100vh;
    padding-bottom: 200px;
    &__panel {
        display: flex;
        flex-direction: column;
        align-items: center;
        &__button {
            width: 300px;
            height: 50px;
            background-color: rgba($color: #000000, $alpha: 0);
            border: 2px solid #fff;
            border-radius: 7px;
            margin-bottom: 15px;
            color: #fff;
            transition: all 0.4s;
            &:hover {
                background-color: #fff;
                color: #000;
            }
        }
        &__header {
            color: #fff;
            margin-bottom: 20px;
        }
    }
}
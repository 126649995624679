@media (max-width: 1351px) {
    .basket {
        &__item {
            width: 710px;
            display: grid;
            height: 170px;
            align-items: center;
            border: 2px solid #696969;
            border-radius: 30px;
            grid-template-columns: 173px 190px 120px 100px 50px;
            column-gap: 15px;
            padding: 0 14px;
            &__img {
                width: 165px;
                height: 135px;
            }
            &__header {
                font-size: 23px;
                font-weight: 500;
                color: #fff;
                cursor: pointer;
            }
            &__price {
                font-size: 26px;
                font-weight: 300;
            }
            &__quantity {
                margin-top: 4px;
                &__decr {
                    margin-bottom: 3px;
                }
            }
            &__remove {
                margin-top: 0;
                margin-bottom: 4px;
                width: 35px;
                height: 35px;
                svg {
                    text-align: center;
                    width: 35px;
                    height: 35px;
                    padding-bottom: 5px;
                }
            }
            
        }
    }
}

@media (max-width: 959px) {
    .basket {
        &__item {
            grid-template-columns: 173px 190px 110px 87px 50px;
            width: 688px;
            &__header {
                font-size: 23px;
                font-weight: 500;
                color: #fff;
                cursor: pointer;
            }
            &__price {
                font-size: 23px;
                justify-self: center;
            }
            &__quantity {
                &__decr {
                    margin-bottom: 3px;
                }
            }
            
        }
    }
}

@media (max-width: 687px) {
    .basket {
        &__item {
            padding: 0 7px;
            grid-template-columns: 110px 120px 70px 64px 50px;
            column-gap: 10px;
            width: 450px;
            height: 130px;
            &__img {
                width: 110px;
                height: 94px;
            }
            &__header {
                font-size: 20px;
                font-weight: 500;
                color: #fff;
                cursor: pointer;
            }
            &__price {
                font-size: 17px;
                font-weight: 300;
            }
            &__quantity {
                width: 70px;
                height: 32px;
                font-size: 20px;
                &__decr {
                    margin-top: 4px;
                }
            }
            &__remove {
                width: 30px;
                height: 30px;
                margin-bottom: 7px;
                svg {
                    text-align: center;
                    width: 30px;
                    height: 30px;
                    padding-bottom: 5px;
                }
            }
            
        }
    }
}

@media (max-width: 449px) {
    .basket {
        &__item {
            padding: 0 5px;
            grid-template-columns: 95px 100px 70px 55px 50px;
            width: 400px;
            height: 105px;
            &__img {
                width: 95px;
                height: 80px;
            }
            &__header {
                font-size: 18px;
                font-weight: 500;
                color: #fff;
                cursor: pointer;
            }
            &__price {
                font-size: 15px;
                justify-self: end;
            }
            &__quantity {
                &__decr {
                    margin-bottom: 3px;
                }
            }
            &__remove {
                margin-top: 1px;
                width: 26px;
                height: 26px;
                svg {
                    padding-top: 1px;
                    text-align: center;
                    width: 26px;
                    height: 26px;
                    padding-bottom: 5px;
                }
            }
            
        }
    }
}

@media (max-width: 400px) {
    .basket {
        &__item {
            position: relative;
            padding: 0 5px;
            grid-template-columns: 135px 136px 26px;
            grid-template-rows: auto auto 36px;
            gap: 10px;
            width: 316px;
            height: 132px;
            &__img {
                position: absolute;
                left: 5px;
                top: 10px;
                width: 135px;
                height: 108px;
                align-self: center;
                margin-bottom: 3px;
            }
            &__header {
                grid-column: 2 / 3;
                grid-row: 1 / 2;
                font-size: 18px;
                font-weight: 500;
                color: #fff;
                cursor: pointer;
            }
            &__price {
                grid-column: 2 / 3;
                grid-row: 2 / 3;
                font-size: 16px;
                justify-self: start;
            }
            &__quantity {
                padding-top: 2px;
                position: absolute;
                bottom: 0;
                left: 59%;
                transform: translateX(-50%);
                margin-top: 0;
                margin-bottom: 8px;
                &__decr {
                    margin-bottom: 5px;
                }
                &__incr {
                    margin-bottom: 1px;
                }
            }
            &__remove {
                position: absolute;
                right: 5px;
                margin-top: 2px;
                width: 26px;
                height: 26px;
                svg {
                    padding-top: 1px;
                    text-align: center;
                    width: 26px;
                    height: 26px;
                    padding-bottom: 5px;
                }
            }
            
        }
    }
}
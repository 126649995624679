.dropdown {
    position: relative;
    display: inline-block;
    margin-top: 15px;
    &__toggle {
        padding: 10px;
        font-size: 16px;
        background-color: #fff;
        color: #000;
        border: 1px solid #808080;
        border-radius: 5px;
        min-width: 145px;
        display: flex; /* Используем flexbox */
        align-items: center; /* Центрируем по вертикали */
        justify-content: center; /* Центрируем по горизонтали */
        cursor: pointer;
        &::after {
            content: '';
            margin-left: 5px;
            margin-top: 4px;
            border-width: 5px 5px 0 5px; /* Ширина и высота треугольника */
            border-style: solid;
            border-color: #808080 transparent transparent transparent; /* Цвет треугольника */
        }
    }
    &__menu {
        position: absolute;
        padding: 0;
        top: 41px;
        left: 0;
        background-color: white;
        border: 1px solid #ccc;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        z-index: 1;
        width: 160px;
        min-height: 44px;
        max-height: 300px;
        overflow: auto;
        list-style: none;
        margin-top: 0;
        li {
            padding: 10px;
            cursor: pointer;
            &:hover {
                background-color: #f1f1f1;
            }
        }
    }
}
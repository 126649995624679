@media (max-width: 799px) {
    .footer {
        width: 100%;
        padding: 25px 50px;
    }
}
@media (max-width: 719px) {
    .footer {
        padding: 25px 25px;
    }
}

@media (max-width: 624px) {
    .footer {
        height: 330px;
        padding: 25px 25px;
        flex-direction: column;
        align-items: center;
        &__social {
            display: flex;
            flex-direction: column;
            align-items: center;
            &__logo {
                font-size: 30px;
            }
            &__inst {
                margin-top: 10px;
            }
        }
        &__info {
            &__privacy {
                margin-top: 10px;
            }
        }
    }
}
@media (max-width: 580px) {
    .create__product {
        &__content {
            width: 400px;
        }
        &__form {
            &__info {
                &__wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    
                }
                &__input {
                    width: 100%;
                    margin-top: 5px;
                }
                &__delete {
                    margin-top: 5px;
                }
            }
        }
    }
}

@media (max-width: 470px) {
    .create__product {
        &__content {
            width: 350px;
        }
    }
}

@media (max-width: 380px) {
    .create__product {
        &__content {
            width: 90%;
        }
        &__form {
            &__input {
                width: 100%;
            }
        }
    }
}
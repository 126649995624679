.type__bar {
    display: grid;
    grid-template-columns: repeat(6, 200px);
    row-gap: 25px;
    grid-auto-rows: 45px;
    column-gap: 30px;
    margin-top: 37px;
    &__item {
        position: relative;
        width: 200px;
        height: 45px;
        border: 2px solid #C82A2A;
        color: #fff;
        font-size: 24px;
        text-align: center;
        border-radius: 25px;
        cursor: pointer;
        transition: all 0.3s;
        padding-top: 6px;
        &.active {
            background-color: #C82A2A;
        }
        &:hover {
            background-color: #A02222;
        }
        &__delete {
            width: 30px;
            position: absolute;
            right: 5px;
            top: -38px;
        }
        &__edit {
            width: 30px;
            position: absolute;
            right: 29px;
            top: -38px;
            svg {
                fill: #F4F4F4;
            }
        }
    }
}
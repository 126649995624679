

@media (max-width: 1351px) {
    .user {
        &__item {
            grid-template-columns: 190px 200px 140px 200px 180px;
            width: 960px;
        }
    }
}

@media (max-width: 959px) {
    .user {
        &__item {
            width: 688px;
            grid-template-columns: 180px 110px 168px 180px;
            grid-template-rows: 50px 50px;
        }
        &__name {
            font-size: 20px;
            font-weight: 900;
            grid-column: 1 / 5;
            grid-row: 1 /2;
            justify-self: center;
            text-decoration: underline;
        }
    }
}

@media (max-width: 687px) {
    .user {
        &__item {
            width: 100%;
            grid-template-columns: 100%;
            grid-template-rows: repeat(5, 50px);
            grid-auto-rows: 50px;
        }
        &__phone {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
            justify-self: center;
        }
        &__purchased {
            grid-column: 1 / 2;
            grid-row: 3 / 4;
            justify-self: center;
        }
        &__spended {
            grid-column: 1 / 2;
            grid-row: 4 / 5;
            justify-self: center;
        }
        &__registered {
            grid-column: 1 / 2;
            grid-row: 5 / 6;
            justify-self: center;
        }
    }
}
.footer {
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 145px;
    background-color: rgba($color: #212121, $alpha: 0.8);
    backdrop-filter: blur(5px);
    padding: 25px 80px 37px;
    z-index: 1;
    &__social {
        &__logo {
            font-size: 24px;
            color: #fff;
            font-weight: 300;
            line-height: 20px;
        }
        &__inst {
            display: flex;
            margin-top: 25px;
            text-decoration: none;
            &__link {
                padding-top: 4px;
                text-decoration: underline;
                font-size: 18px;
                font-weight: 300;
                color: #fff;
                margin-left: 6px;
                opacity: 0.85;
            }
        }
    }
    &__info {
        text-align: center;
        &__address {
            display: flex;
            align-items: center;
            &__text {
                margin-left: 5px;
                color: #fff;
            }
        }
        &__privacy {
            display: block;
            margin-top: 25px;
            color: #E37232;
            cursor: pointer;
        }
    }
    &__contacts {
        &__tel {
            display: flex;
            align-items: center;
            &__number {
                color: #F8F8F8;
                opacity: 0.85;
                font-size: 18px;
                text-decoration: none;
                margin-left: 1px;
            }
            &-second {
                margin-top: 20px;
            }
        }
    }
}
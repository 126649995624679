.user {
    &__item {
        display: grid;
        padding: 15px 25px;
        grid-template-columns: repeat(4, 220px) 180px;
        width: 1104px; 
        background-color: rgba($color: #212121, $alpha: .8);
        border-radius: 20px;
        margin: 0 auto;
        margin-top: 15px;
        color: #fff;
    }
    &__name {
        cursor: pointer;
    }
}
.category__bar {
    display: block;
    position: absolute;
    top: -6px;
    width: 100%;
    background-color: rgba($color: #212121, $alpha: 0.8);
    backdrop-filter: blur(5px);
    color: white;
    display: flex;
    justify-content: space-between;
    z-index: 9;
    &__content {
        min-width: 900px;
        max-width: 1400px;
        min-height: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 0;
    }
    &__item {
        margin: 0 15px;
        font-size: 24px;
        font-weight: 300;
        list-style-type: none;
        width: auto;
        cursor: pointer;
        transition: 0.3s all;
        margin-bottom: 4px;
        &__link {
            color: #fff;
            &:hover {
                color: #A02222;
                text-shadow: #C82A2A 1px 0 15px;
            }
        }
    }
    &__add {
        position: absolute;
        right: 15px;
        font-size: 20px;
        top: 3px;
        cursor: pointer;
    }
}




@media (max-width: 1351px) {
    .category__item {
        &__header {
            &__wrapper {
                padding: 0 10px;
            }
        }
        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            row-gap: 30px;
            column-gap: 23px;
        }
    }
    
}

@media (max-width: 959px) {
    .category__item {
        &__list {
            column-gap: 15px
        }
    }
}

@media (max-width: 687px) {
    .category__item {
        &__header {
            &__wrapper {
                flex-direction: column;
                justify-content: center;
            }
        }
        &__list {
            margin-top: 30px;
        }
    }
}

.loader-overlay {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999; /* Выше всех остальных элементов */
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: all 0.5s;
    &.transparent {
        background: rgba(0, 0, 0, 0.5); /* Прозрачный затемнённый фон */
    }
    &.opaque {
        background: #262626;
    }
    &.inactive {
        opacity: 0;
        transition: all 0.5s;
        pointer-events: none;
    }
}

.loader {
    color: white;
    font-size: 24px;
    text-align: center;
    &inactive {
        opacity: 0;
        transition: all 0.5s;
    }
    &__logo-text {
        margin-right: 2px;
    }
}

.spinner {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
@media (max-width: 1351px) {
    .category__bar {
        &__item {
            font-size: 20px;
        }
    }
}

@media (max-width: 960px) {
    .category__bar {
        &__content {
            min-width: 320px;
        }
    }
}

@media (max-width: 590px) {
    .category__bar {
        &__content {
            min-width: 320px;
        }
        &__item {
            font-size: 17px;
        }
    }
}


@media (max-width: 1439px){
    .type__bar {
        grid-template-columns: repeat( 5, 191px);
        &__item {
            width: 191px;
        }
    }
}

@media (max-width: 1077px){
    .type__bar {
        grid-template-columns: repeat( 4, 188px);
        column-gap: 15px;
        &__item {
            width: 188px;
        }
    }
}

@media (max-width: 959px){
    .type__bar {
        grid-template-columns: repeat( 4, 167px);
        column-gap: 15px;
        &__item {
            width: 100%;
        }
    }
}

@media (max-width: 768px) {
    .type__bar {
        grid-template-columns: repeat(4, 147px);
        column-gap: 10px;
        &__item {
            width: 100%;
        }
    }
}

@media (max-width: 713px) {
    .type__bar {
        grid-template-columns: repeat(3, 148px);
        column-gap: 10px;
        &__item {
            width: 100%;
            font-size: 21px;
            padding-top: 7px;
        }
    }
}

@media (max-width: 499px) {
    .type__bar {
        justify-content: center;
        grid-template-columns: repeat(3, 130px);
        column-gap: 5px;
        row-gap: 10px;
        &__item {
            width: 100%;
        }
    }
}

@media (max-width: 399px) {
    .type__bar {
        justify-content: center;
        grid-template-columns: repeat(3, 103px);
        column-gap: 5px;
        row-gap: 5px;
        &__item {
            width: 100%;
            font-size: 17px;
            height: 40px;
        }
    }
}
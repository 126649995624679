.category__item {
    margin-top: 100px;
    &__header {
        color: #fff;
        font-size: 40px;
        cursor: pointer;
        margin: 0;
        &__wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    &__link {
        color: white;
        font-size: 24px;
        font-weight: 300;
        opacity: 0.5;
        text-decoration: underline;
        cursor: pointer;
    }
    &__list {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(5, 246px);
        column-gap: 30px;
        margin-top: 50px;
    }
}


@media (max-width: 500px) {
        .confirm__modal {
            &__content {
                width: 400px;
                height: 200px;
            }
    }
}

@media (max-width: 450px) {
    .confirm__modal {
        &__content {
            width: 350px;
        }
    }
}

@media (max-width: 400px) {
    .confirm__modal {
        &__content {
            width: 300px;
            min-height: 150px;
        }
        &__btn {
            width: 100px;
            height: 40px;
            font-size: 14px;
            &-cancel {
                right: 133px;
                width: 100px;
                height: 40px;
                font-size: 14px;
            }
        }
}
}
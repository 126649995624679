.navbar {
    display: flex;
    align-items: center;
    position: absolute;
    max-width: 100%;
    width: 100%;
    height: 86px;
    background-color: rgba($color: #212121, $alpha: 0.8);
    backdrop-filter: blur(5px);
    padding: 0 80px;
    z-index: 100;
    &__auth__mobile {
        display: none;
    }
    &__button {
        width: 120px;
        height: 40px;
        border: none;
        border-radius: 26px;
        font-size: 24px;
        color: #fff;
        padding-bottom: 6px;
    }
    &__basket {
        padding-top: 4px;
        &__mobile {
            display: none;
        }
    }
    &__logo {
        position: absolute;
        left: 80px;
        color: white;
        font-size: 24px;
        font-weight: 300;
        line-height: 24px;
        margin-bottom: 2px;
        cursor: pointer;
    }
    &__tel {
        color: #F8F8F8;
        font-weight: 300;
        font-size: 18px;
        text-decoration: none;
        margin: 3px;
        margin-left: 20px;
    }
    &__menu {
        display: flex;
        align-items: center;
        position: absolute;
        right: 80px;
        &__mobile {
            display: none;
        }
        &__wrapper {
            min-width: 255px;
            margin-left: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &__wrapper-logIn {
            position: absolute;
            right: 80px;
            min-width: 290px;
            margin-left: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    &__basket {
        background-color: #C82A2A;
        transition: all 0.5s;
        svg {
            display: inline-block;
            transition: all 0.3s;
            &:hover {
                animation: float 1.3s infinite;
            }
        }
        &:hover {
            background-color: #A02222;
        }
    }
    &__signOut {
        background-color: rgba($color: #000000, $alpha: 0);
        border: 2px solid #696969;
        transition: all 0.3s;
        font-size: 22px;
        &:hover {
            background-color: rgba($color: #fff, $alpha: .07);
            color: #000;
        }
    }
    &__auth {
        background-color: #C82A2A;
        margin-left: 20px;
        transition: all 0.3s;
        &:hover {
            background-color: #A02222;
        }
    }
    &__admin {
        position: absolute;
        left: 0;
        bottom: -30px;
        width: 100%;
        height: 30px;
        &__wrapper {
            display: flex;
            width: 270px;
            justify-content: space-around;
            margin: 0 auto;
        }
        &__item {
            color: #fff;
            font-size: 18px;
            font-weight: 400;
            cursor: pointer;
            transition: all 0.4s;
            &:hover {
                text-shadow: #fff 0 0 10px;
            }
        }
    }

    &__hamburger {
        display: none;
        &__line {
            display: none;
            background-color: #fff;
            width: 32px;
            height: 1px;
        }
    }
}

.ml-5 {
    margin-left: 5px;
}

@keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-3px);
    }
    100% {
      transform: translateY(0);
    }
  }
@media (max-width: 959px) {
    .product {
        width: 218px;
        &__img {
            width: 218px;
            height: 170px;
        }
        &__name {
            font-size: 22px;
        }
        &__buy__wrapper {
            margin-top: 18px;
        }
        &__buy__button {
            &__wrapper {
                svg {
                    margin-top: 20px;
                }
            }
        }
    }
}

@media (max-width: 687px) {
    .product {
        width: 210px;
        &__img {
            width: 210px;
            height: 160px;
        }
        &__buy__button {
            &__wrapper {
                svg {
                    margin-top: 19px;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .product {
        width: 195px;
        &__img {
            width: 195px;
            height: 143px;
        }
        &__name {
            font-size: 22px;
        }
        &__price {
            font-size: 20px;
            &-old {
                font-size: 17px;
                left: 25px;
            }
        }
        &__buy__button {
            width: 90px;
            height: 40px;
            font-size: 14px;
            &__wrapper {
                width: 100%;
                position: absolute;
                transform: translateX(-50%) translateY(-11px);
                left: 50%;
                transition: all 0.5s;
                top: 22px;
                svg {
                    margin-top: 19px;
                }
                &.active {
                    transform: translateX(-50%) translateY(-48px);
                    transition: all 0.5s;
                }
            }
            &:hover {
                background-color: #A02222;
            }
        }
    }
}

@media (max-width: 450px) {
    .product {
        width: 190px;
        &__img {
            width: 190px;
            height: 143px;
        }
        &__name {
            font-size: 22px;
        }
        &__price {
            font-size: 20px;
            &-old {
                font-size: 17px;
                left: 25px;
            }
        }
        &__buy__button {
            width: 90px;
            height: 40px;
            font-size: 14px;
            &__wrapper {
                top: 22px;
                svg {
                    margin-top: 18px;
                }
            }
        }
    }
}

@media (max-width: 399px) {
    .product {
        width: 150px;
        &__img {
            width: 150px;
            height: 113px;
        }
        &__name {
            font-size: 16px;
        }
        &__price {
            font-size: 16px;
            &-old {
                font-size: 13px;
                left: 25px;
                top: -9px;
            }
        }
        &__buy__button {
            width: 76px;
            height: 35px;
            font-size: 14px;
            &__wrapper {
                top: 20px;
            }
        }
    }
}
.basket__item {
    display: grid;
    width: 1000px;
    height: 200px;
    align-items: center;
    border: 2px solid #696969;
    border-radius: 30px;
    grid-template-columns: 173px 355px 155px 135px 50px;
    column-gap: 30px;
    padding: 0 14px;
    &__img {
        width: 185px;
        height: 130px;
        border-radius: 20px;
        cursor: pointer;
        object-fit: cover;
        border: 1px solid #505050;
        opacity: 0;
        transition: all .5s;
        &.active {
            opacity: 1;
            transition: all .5s;
        }
    }
    &__header {
        font-size: 35px;
        color: #fff;
        cursor: pointer;
    }
    &__price {
        font-size: 35px;
        font-weight: 300;
        color: #fff;
    }
    &__quantity {
        display: flex;
        width: 112px;
        height: 43px;
        font-size: 24px;
        justify-content: space-around;
        align-items: center;
        color: #fff;
        border: 2px solid #C82A2A;
        border-radius: 25px;
        padding-bottom: 3px;
        &__decr {
            margin-bottom: 2px;
        }
        span {
            font-size: 33px;
        }
    }
    &__remove {
        width: 43px;
        height: 43px;
        cursor: pointer;
        svg {
            padding-top: 1px;
            text-align: center;
            width: 33px;
            height: 33px;
            margin-top: 6px;
        }
    }
}


@media (max-width:1351px) {
    #container {
        width: 960px;
    }
}

@media (max-width:959px) {
    #container {
        width: 688px;
    }
}

@media (max-width:687px) {
    #container {
        width: 450px;
    }
}

@media (max-width:449px) {
    #container {
        width: 400px;
    }
}

@media (max-width:400px) {
    #container {
        width: 320px;
    }
}
.product {
    position: relative;
    width: 247px;
    &__img {
        width: 246px;
        height: 190px;
        border-radius: 20px;
        box-shadow: 0 8px 14px rgba($color: #000000, $alpha: 0.25);
        object-fit: cover;
        cursor: pointer;
        opacity: 0;
        transition: all 0.7s;
        z-index: 2;
        &.active {
            opacity: 1;
            transition: all .7s;
            z-index: 2;
        }
    }
    &__name {
        display: flex;
        color: #F4F4F4;
        font-size: 24px;
        font-weight: 400;
        margin-top: 10px;
        text-shadow: #000 0 8px 14px;
        span {
            width: 15px;
            margin-left: 2px;
            padding-top: 5px;
            cursor: pointer;
            svg {
                fill: rgba($color: #fff, $alpha: .6);
                margin: 0;
                transform: scale(0.73) translateY(-3px);
            }
        }
    }
    &__buy__wrapper {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }
    &__price {
        display: flex;
        font-size: 26px;
        font-weight: 300;
        color: #F4F4F4;
        text-shadow: #000 0 8px 14px;
        &-old {
            position: absolute;
            top: -14px;
            left: 35px;
            color: rgba($color: #fff, $alpha: 0.6);
            font-weight: 300;
            font-size: 20px;
            text-decoration: line-through;
            text-decoration-color: #C82A2A;
        }
        span {
            width: 10px;
            display: inline-block;
            margin-left: 2px;
            cursor: pointer;
            svg {
                fill: rgba($color: #fff, $alpha: .6);
                margin: 0;
                transform: scale(0.73);
            }
        }
    }
    &__buy__button {
        position: relative;
        width: 105px;
        height: 43px;
        border: none;
        border-radius: 25px;
        background-color: #C82A2A;
        box-shadow: 0 8px 14px rgba($color: #000000, $alpha: 0.25);
        color: #F4F4F4;
        font-size: 18px;
        transition: all 0.5s;
        overflow: hidden;
        &__wrapper {
            width: 100%;
            position: absolute;
            transform: translateX(-50%) translateY(-11px);
            left: 50%;
            transition: all 0.5s;
            svg {
                margin-top: 19px;
            }
            &.active {
                transform: translateX(-50%) translateY(-52px);
                transition: all 0.5s;
            }
        }
        &:hover {
            background-color: #A02222;
        }
    }
    &__delete {
        position: absolute;
        top: 4px;
        right: 2px;
        svg {
            fill: #F4F4F4;
            width: 33px;
            cursor: pointer;
        }
    }
}
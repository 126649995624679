.order {
    min-height: 100vh;
    padding-top: 145px;
    padding-bottom: 370px;
    &__header {
        color: #fff;
        text-align: center;
    }
    &__subheader {
        color: rgba($color: #fff, $alpha: .7);
        font-weight: 300;
        margin-top: 40px;
    }
}

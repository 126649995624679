@media (max-width: 570px) {
    .buy__form__modal {
        &__content {
            width: 400px;
            min-height: 400px;
        }
    }
}

@media (max-width: 450px) {
    .buy__form__modal {
        &__content {
            width: 350px;
        }
    }
}

@media (max-width: 450px) {
    .buy__form__modal {
        &__content {
            width: 350px;
        }
    }
}

@media (max-width: 380px) {
    .buy__form__modal {
        &__content {
            width: 310px;
        }
        &__form {
            &__input {
                width: 250px;
            }
        }
        &__total__price {
            font-size: 18px;
            bottom: 28px;
        }
    }
}
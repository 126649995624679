@media (max-width: 1630px) {
    .navbar {
        height: 70px;
        &__menu {
            &__phones {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

@media (max-width: 960px) {
    .navbar {
        position: relative;
        padding: 0 20px;
        justify-content: space-between;
        height: 50px;
        &__logo {
            left: 20px;
        }
        &__menu {
            display: none;
            &__wrapper-logIn {
                display: none;
            }
        }
        &__hamburger {
            position: absolute;
            right: 20px;
            display: none;
            width: 32px;
            height: 22px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &__line {
                display: block;
            }
        }
        &__basket {
            &__mobile {
                display: block;
                position: absolute;
                right: 80px;
                width: 100px;
                height: 40px;
                border-radius: 20px;
                border: none;
                background-color: #C82A2A;
                &:hover {
                    background-color: #A02222;
                }
            }
        }
        &__auth__mobile {
            display: block;
            position: absolute;
            width: 100px;
            height: 40px;
            right: 80px;
            color: #fff;
            text-decoration: none;
            border: none;
            background-color: #C82A2A;
            transition: all 0.3s;
            border-radius: 20px;
            &:hover {
                background-color: #A02222;
            }
        }
        &__hamburger {
            &__close__wrapper {
                position: absolute;
                right: 15px;
                width: 50px;
                height: 50px;
            } 
            &__line-close {
                position: absolute;
                right: 0;
                top: 20px;
                width: 32px;
                height: 2px;
                background-color: #fff;
                transform: rotate(45deg);
                &-second {
                    position: absolute;
                    right: 0;
                    top: 20px;
                    width: 32px;
                    height: 2px;
                    background-color: #fff;
                    transform: rotate(-45deg);
                }
            }
            
        }
        &__menu {
            display: none;
            &__mobile {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                opacity: 0;
                transform: translateY(-100%);
                transition: all 1.3s;
                pointer-events: none;
                &__header {
                    width: 100%;
                    height: 50px;
                    background-color: #242424;
                }
                &.show {
                    opacity: 1;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: #3a3a3a;
                    transform: translateY(0);
                    transition: all 0.5s;
                    pointer-events: all;
                }
                &__item {
                    color: #fff;
                    width: 190px;
                    height: 35px;
                    padding-left: 15px;
                }
            }
        }
        &__tel__mobile {
            color: #fff;
            text-decoration: none;
        }
        &__admin__wrapper {
            display: none;
        }
    }
}

@media (max-width: 400px) {
    .navbar {
        &__logo {
            font-size: 20px;
        }
        &__basket {
            &__mobile {
                right: 68px;
            }
        }
        &__auth__mobile {
            right: 68px;
        }
    }
}

@media (max-width: 380px) {
    .navbar {
        &__basket {
            &__mobile {
                width: 80px;
                height: 38px;
                right: 68px;
            }
        }
        &__auth__mobile {
            width: 80px;
            height: 38px;
        }
    }
}
.change__product__image__modal__form__file {
    &-first {
        margin-top: 5px;
    }
    &-second {
        margin-top: 5px;
    }
    &-text {
        margin-top: 10px;
        font-size: 18px;
        font-weight: 500;
    }
}
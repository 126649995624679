@use './variables.scss';

* {
    box-sizing: border-box;
}



body {
    background-color: #262626;
    font-family: "M PLUS 1p", sans-serif;
    font-weight: 400;
    font-style: normal;
}

h2 {
    margin: 0;
}

#container {
    width: 1352px;
    padding: 0;
    margin: 0 auto;
}

.container {
    width: 1352px;
    padding: 0 auto;
    margin: 0 auto;
}

.app {
    min-height: 100vh;
    position: relative;
}

.__spinner {
    display: block;
    margin: 0 auto;
    width: 25px;
}

.auth {
    padding-bottom: 100px;
}

.auth {
    background-color: rgba($color: #000000, $alpha: .4);
    display: flex;
    min-height: 900px;
    width: 100vw;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s;
    &__header {
        font-size: 32px;
        margin: 0;
        margin-bottom: 37px;
    }
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 720px;
        min-height: 300px;
        padding: 25px 0 35px 0;
        background-color: #EDEDED;
        margin-top: 75px;
        border-radius: 20px;
        opacity: 0;
        transition: all 0.5s;
        box-shadow: 0 8px 14px rgba($color: #000000, $alpha: 0.25);
        &.show {
            opacity: 1;
            transition: all 0.5s;
        }
    }
    &.show {
        opacity: 1;
        pointer-events: all;
        transition: all 0.5s;
        z-index: 10;
    }
    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        &__input {
            width: 578px;
            padding-left: 15px;
            color: #343434;
            font-weight: 300;
            font-size: 18px;
            height: 50px;
            border-radius: 50px;
            background-color: #D9D9D9;
            border: 1px solid #808080;
            box-shadow: inset 2px 2px 5px 0 rgba(0, 0, 0, 0.2), inset -6px -6px 10px 0 rgba(255, 255, 255, 0.5);
            &.name {
                margin-bottom: 15px;
            }
            &.sms {
                margin-top: 25px;
            }
        }
        &__password-wrapper {
            position: relative;
            width: 578px;
            height: 50px;
            margin-top: 15px;
        }
        &__toggle-password {
            position: absolute;
            right: 12px;
            top: 13px;
            background-color: rgba($color: #000000, $alpha: 0);
            border: none;
        }
        &__password {
            &__show {
                margin-top: 3px;
            }
            &__hide {
                margin-top: 9px;
            }
        }
        &__wrapper__policy {
            position: relative;
            width: 578px;
            margin-top: 15px;
            padding-left: 25px;
        }
        &__forgot {
            color: #212121;
            font-size: 18px;
            text-decoration: underline;
            cursor: pointer;
        }
        &__policy {
            color: #212121;
            font-size: 18px;
            margin-left: 20px;
            margin-top: 4px;
            &-link {
                margin-left: 4px;
                font-size: 18px;
                font-weight: 500;
                color: #000000;
            }
        }
        &__checkbox {
            position: absolute;
            top: 6px;
            left: 20px;
            width: 16px;
            height: 16px;
            box-shadow: 0 5px 16px rgba($color: #000000, $alpha: 0.25);
        }
    }
    &__or__reg {
        margin-top: 20px;
        a {
            color: #212121;
            font-size: 20px;
        }
    }
    &__btn {
        width: 578px;
        border-radius: 30px;
        height: 60px;
        border: none;
        color: #FFFFFF;
        font-weight: 500;
        background-color: #C82A2A;
        margin-top: 20px;
        transition: all 0.3s;
        font-size: 24px;
        &:hover {
            content: '';
            box-shadow: inset 2px 2px 5px 0 rgba(0, 0, 0, 0.2), 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
        }
    }
}

.sms__resender {
    margin-top: 10px;
    &__btn {
        border-radius: 8px;
        background-color: #EDEDED;
        border: none;
        text-decoration: underline;
    }
}


@media (max-width: 1351px) {
    .product__page {
        &__img {
            width: 500px;
            height: 400px;
            margin-right: 30px;
        }
        &__name {
            font-size: 45px;
            line-height: 50px;
        }
        &__price {
            font-size: 40px;
            &-old {
                font-size: 30px;
            }
        }
        &__about {
            &__wrapper {
                width: 500px;
                padding-bottom: 105px;
                min-height: 400px;
            }
        }
        &__buy {
            &__wrapper {
                position: absolute;
                bottom: -0px;
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
            }
            &__button {
                width: 190px;
                height: 60px;
                font-size: 25px;
            }
        }
        &__type {
            font-size: 25px;
            margin-bottom: 3px;
        }
        &__info {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            color: #fff;
            font-size: 25px;
            font-weight: 300;
            &__item {
                font-size: 25px;
            }
            &-descr {
                color: rgba($color: #F4F4F4, $alpha: .6);
                font-size: 25px;
                margin-top: 5px;
            }
            span {
                display: flex;
                margin-left: 12px;
                svg {
                    fill: #F4F4F4;
                }
            }
            &__delete {
                width: 30px;
                margin-left: 3px;
            }
            &__add {
                padding: 0;
                color: #F4F4F4;
                width: 155px;
                height: 30px;
                font-size: 24px;
                border-radius: 30px;
                border: none;
                background-color: rgba($color: #000000, $alpha: 0);
                text-decoration: underline;
            }
        }
    }
}

@media (max-width: 959px) {
    .product__page {
        padding-top: 30px;
        &__img {
            width: 390px;
            height: 290px;
            margin-right: 17px;
        }
        &__name {
            font-size: 33px;
            line-height: 40px;
            margin-bottom: 0;
        }
        &__info {
            &__wrapper {
                margin-top: 5px;
            }
            &__item {
                font-size: 18px;
            }
            &-descr {
                font-size: 18px;
                margin-top: 2px;
            }
            &__add {
                font-size: 20px;
                width: 130px;
            }
        }
        &__type {
            font-size: 21px;
            margin-bottom: 3px;
        }
        &__price {
            font-size: 25px;
            &-old {
                font-size: 20px;
                top: -10px;
                left: 40px;
            }
        }
        &__buy {
            &__button {
                width: 130px;
                height: 50px;
                font-size: 18px;
            }
        }
        &__about {
            &__wrapper {
                min-height: 290px;
                padding-bottom: 70px;
            }
        }
    }
}

@media (max-width: 687px) {
    .product__page {
        padding-top: 40px;
        &__wrapper {
            flex-direction: column;
        }
        &__img {
            width: 100%;
            height: 330px;
            margin-right: 0;
        }
        &__name {
            font-size: 40px;
            margin-bottom: 10px;
            margin-top: 15px;
            font-weight: 400;
        }
        &__about {
            &__wrapper {
                width: 100%;
                position: relative;
            }
        }
        &__info {
            &__item {
                font-size: 20px;
            }
            &-descr {
                font-size: 20px;
                margin-top: 1px;
            }
        }
        &__buy {
            width: 100%;
            &__wrapper {
                right: 0;
                bottom: 0;
                width: 100%;
                align-items: center;
            }
            &__button {
                width: 200px;
                height: 50px;
                font-size: 24px;
            }
        }
        &__price {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 624px) {
    .product__page {
        padding-bottom: 370px;
    }
}

@media (max-width: 450px) {
    .product__page {
        &__wrapper {
            margin-top: 30px;
        }
        &__img {
            height: 290px;
        }
        &__name {
            font-size: 32px;
        }
    }
}

@media (max-width: 400px) {
    .product__page {
        &__img {
            height: 230px;
        }
        
    }
}
.to__shop {
    display: flex;
    width: 240px;
    align-items: center;
    min-width: 200px;
    font-size: 24px;
    font-weight: 300;
    color: #fff;
    cursor: pointer;
    margin: 0;
}
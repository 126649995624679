.user__page__order {
    position: relative;
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    width: 600px;
    min-height: 140px;
    color: #fff; 
    background-color: rgba($color: #212121, $alpha: .8);
    border-radius: 20px;
    padding: 15px 15px 15px 15px;
    margin-top: 25px;
    box-shadow: 0 8px 14px rgba($color: #000000, $alpha: 0.25);
    &__date {
        color: #fff;
        font-size: 24px;
    }
    &__time {
        font-size: 24px;
    }
    &__products {
        margin-top: 10px;
        font-size: 18px;
        color: rgba($color: #fff, $alpha: .7);
        span {
            font-size: 24px;
        }
    }
    &__sum {
        font-size: 30px;
        margin-top: 10px;
    }
    &__remove {
        position: absolute;
        top: 6px;
        right: -5px;
        width: 43px;
        height: 43px;
        cursor: pointer;
        svg {
            padding-top: 1px;
            text-align: center;
            width: 33px;
            height: 33px;
            border-radius: 100%;
            padding-bottom: 2px;
        }
    }
}
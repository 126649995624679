@media (max-width: 1351px) {
    .user__page {
        padding-top: 145px;
        padding-bottom: 200px;
        &__header {
            color: #fff;
            margin-top: 50px;
        }
        &__order__list {
            display: flex;
            width: 100%;
            margin: 0 auto;
            flex-wrap: wrap;
            justify-content: space-between;
            &__header {
                color: rgba($color: #fff, $alpha: .7);
                font-weight: 300;
                margin-top: 40px;
            }
        }
        &__last__month {
            color: #fff;
            width: 200px;
            margin: 0 auto;
            text-align: center;
            margin-top: 20px;
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
@media (max-width: 960px) {
    .user__page {
        padding-top: 60px;
    }
}

@media (max-width: 687px) {
    .user__page {
        &__order__list {
            justify-content: center;
        }
    }
}

@media (max-width: 624px) {
    .user__page {
        padding-bottom: 400px;
    }
}
.notification {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    min-height: 50px;
    background: rgba($color: #000000, $alpha: .6);
    border-radius: 20px;
    color: white;
    padding: 10px;
    z-index: 10000;
    &__line {
        width: 100%;
        height: 4px;
        &__content {
            width: 100%;
            height: 100%;
            background-color: #fff;
            animation: shrink 3s linear forwards;
            border-radius: 5px;
        }
    }
}

@keyframes shrink {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
}
@media (max-width: 960px) {
    .admin {
        padding-top: 50px;
    }
}

@media (max-width: 500px) {
    .admin {
        &__panel {
            &__header {
                font-size: 23px;
                text-align: center;
            }
        }
    }
}

@media (max-width: 624px) {
    .admin {
        padding-bottom: 380px;
    }
}

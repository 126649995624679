@media (max-width: 1439px) {
    .promo__slider {
        width: 100%;
        &__arrow {
            &-prev {
                width: 63px;
                left: -7px;
            }
            &-next {
                width: 63px;
                right: -7px;
            }
        }
    }
}

@media (max-width: 1366px) {
    .promo__slider {
        &__edit {
            &__img {
                top: 99px;
                right: 152px;
            }
            &__link {
                top: 100px;
                right: 127px;
            }
            &__basket {
                top: 98px;
                right: 101px;
            }
        }
    }
}

// @media (max-width: 1085px) {
//     .promo__slider {
//     }
// }

@media (max-width: 960px) {
    .promo__slider {
        &__edit {
            &__img {
                top: 29px;
                right: 142px;
            }
            &__link {
                top: 30px;
                right: 112px;
            }
            &__basket {
                top: 29px;
                right: 81px;
            }
        }
    }
}

@media (max-width: 650px) {
    .promo__slider {
        &__arrow {
            &-next {
                display: none;
            }
            &-prev {
                display: none;
            }
        }
        &__edit {
            &__img {
                top: 30px;
                right: 102px;
            }
            &__link {
                top: 31px;
                right: 72px;
            }
            &__basket {
                top: 30px;
                right: 41px;
            }
        }
    }
}

@media (max-width: 550px) {
    .promo__slider {
        &__img {
            &.active {
                display: none;
            }
            &.mobile {
                display: block;
                opacity: 0;
                transition: all .5s;
                &.active-mob {
                    display: block;
                    opacity: 1;
                    width: 100%;
                    height: 100%;
                    transition: all .5s;
                }
            }
        }
    }
}


@media (max-width: 1351px) {
    .category__page {
        &__container {
            width: 1078px;
        }
    }
}

@media (max-width: 1077px) {
    .category__page {
        &__container {
            width: 801px;
        }
    }
}

@media (max-width: 959px) {
    .category__page {
        padding-top: 50px;
        &__container {
            width: 714px;
        }
    }
}

@media (max-width: 713px) {
    .category__page {
        padding-top: 50px;
        &__container {
            width: 466px;
        }
    }
}

@media (max-width: 687px) {
    .category__page {
        padding-top: 50px;
        &__container {
            width: 450px;
        }
    }
}

@media (max-width: 687px) {
    .category__page {
        padding-bottom: 380px;
    }
}

@media (max-width: 499px) {
    .category__page {
        padding-top: 50px;
        &__container {
            width: 400px;
        }
    }
}

@media (max-width: 449px) {
    .category__page {
        padding-top: 50px;
        &__container {
            width: 400px;
        }
    }
}

@media (max-width: 399px) {
    .category__page {
        padding-top: 50px;
        &__container {
            width: 320px;
        }
    }
}
.user__page {
    padding-top: 145px;
    padding-bottom: 200px;
    &__header {
        color: #fff;
        margin-top: 50px;
    }
    &__order__list {
        display: flex;
        width: 1220px;
        margin: 0 auto;
        flex-wrap: wrap;
        justify-content: space-between;
        &__header {
            color: rgba($color: #fff, $alpha: .7);
            font-weight: 300;
            margin-top: 40px;
        }
    }
    &__last__month {
        color: #fff;
        width: 200px;
        margin: 0 auto;
        text-align: center;
        margin-top: 20px;
        cursor: pointer;
        text-decoration: underline;
    }
}
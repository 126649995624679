.order__item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 600px;
    min-height: 200px;
    color: #fff; 
    background-color: rgba($color: #212121, $alpha: .8);
    border-radius: 20px;
    padding: 15px 15px 20px 15px;
    margin: 0 auto;
    margin-top: 25px;
    box-shadow: 0 8px 14px rgba($color: #000000, $alpha: 0.25);
    &__time {
        position: absolute;
        top: 14px;
        right: 45px;
        font-size: 20px;
        font-weight: 300;
        color: rgba($color: #fff, $alpha: 0.6);
    }
    &__date {
        right: 44px;
        top: 38px;
        position: absolute;
        font-size: 20px;
        font-weight: 300;
        color: rgba($color: #fff, $alpha: 0.6);
    }
    &__name {
        margin-top: 5px;
        font-size: 33px;
        font-weight: 500;
        cursor: pointer;
    }
    &__phone {
        font-size: 25px;
    }
    &__products {
        margin-top: 10px;
        font-size: 20px;
        font-weight: 300;
        color: rgba($color: #fff, $alpha: 0.8);
    }
    &__delivery {
        margin-top: 15px;
        font-size: 23px;
        color: rgba($color: #fff, $alpha: 0.9);
    }
    &__address {
        font-size: 23px;
        color: rgba($color: #fff, $alpha: 0.9);
        margin-bottom: 8px;
    }
    &__sum {
        font-size: 28px;
        margin-top: 6px;
    }
    &__buy {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__btn {
            height: 38px;
            border-radius: 7px;
            border: 1px solid #00FF00;
            background-color: rgba($color: #000000, $alpha: 0);
            color: #4CBB17;
            transition: all 0.5s;
            &:hover {
                box-shadow: #4CBB17 0 0 6px;
            }
        }
    }
    &__remove {
        position: absolute;
        top: 6px;
        right: -5px;
        width: 43px;
        height: 43px;
        cursor: pointer;
        svg {
            padding-top: 1px;
            text-align: center;
            width: 33px;
            height: 33px;
            border-radius: 100%;
            padding-bottom: 2px;
        }
    }
}
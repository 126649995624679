.product__list {
    display: grid;
    grid-template-columns: repeat(5, 247px);
    grid-template-rows: 323px;
    grid-auto-rows: 323px;
    row-gap: 40px;
    column-gap: 30px;
    margin-top: 50px;
    justify-content: center;
    align-content: center;
}
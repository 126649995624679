.category__page {
    padding-top: 120px;
    padding-bottom: 200px;
    &__container {
        width: 1351px;
        padding: 0;
        margin: 0 auto;
    }
    &__title {
        display: flex;
        color: #fff;
        min-width: 300px;
        margin: 0;
        margin-top: 40px;
        font-size: 48px;
        span {
            display: flex;
            margin-left: 12px;
            svg {
                fill: #F4F4F4;
                cursor: pointer;
            }
        }
    }
    &__delete {
        font-weight: 300;
        color: rgba($color: #fff, $alpha: .5);
        text-decoration: underline;
        cursor: pointer;
        width: 200px;
        margin: 0;
        margin-top: 15px;
    }
}
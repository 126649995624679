

@media (max-width: 1351px) {
    .statistic {
        &__menu {
            width: 960px;
            grid-template-columns: 190px 200px 140px 200px 180px;
        }
    }
}

@media (max-width: 960px) {
    .statistic {
        padding-top: 60px;
    }
}

@media (max-width: 959px) {
    .statistic {
        &__menu {
            width: 688px;
            grid-template-columns: repeat(4, 160px);
            grid-template-rows: 50px 50px;
        }
        &__sort {
            &__reset {
                font-size: 30px;
                font-weight: 900;
                grid-column: 1 / 5;
                grid-row: 1 /2;
                justify-self: center;
                align-items: start;
                text-decoration: underline;
            }
            &__orders {
                justify-self: center;
                align-items: start;
            }
            &__spend {
                justify-self: center;
                align-items: start;
            }
            &__date {
                justify-self: center;
                align-items: start;
            }

        }
        &__last__month {
            justify-self: center;
            align-items: start;
        }
    }
}

@media (max-width: 687px) {
    .statistic {
        &__menu {
            width: 450px;
            grid-template-columns: repeat(3, 140px);
            grid-template-rows: 50px 50px 50px;
        }
        &__last__month {
            font-size: 30px;
            font-weight: 700;
            grid-column: 1 / 5;
            grid-row: 2 / 3;
        }
    }
}

@media (max-width: 624px) {
    .statistic {
        padding-bottom: 370px;
    }
}

@media (max-width: 449px) {
    .statistic {
        &__menu {
            width: 100%;
            grid-template-columns: 100%;
            grid-template-rows: 50px 50px 50px;
            grid-auto-rows: 50px;
        }
        &__sort {
            &__reset {
                font-size: 24px;
                font-weight: 900;
                align-self: center;
                grid-column: 1 / 2;
                text-decoration: underline;
            }
        }
        &__last__month {
            font-size: 24px;
            font-weight: 900;
            grid-column: 1 / 2;
            grid-row: 2 / 3;
            text-decoration: underline;
        }
    }
}
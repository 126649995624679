@media (max-width: 1351px) {
    .basket__list {
        display: grid;
        grid-template-rows: 170px;
        grid-auto-rows: 170px;
        row-gap: 15px;
    }
}

@media (max-width: 687px) {
    .basket__list {
        display: grid;
        grid-template-rows: 130px;
        grid-auto-rows: 130px;
        row-gap: 15px;
    }
}

@media (max-width: 449px) {
    .basket__list {
        display: grid;
        grid-template-rows: 105px;
        grid-auto-rows: 105px;
        row-gap: 15px;
    }
}

@media (max-width: 400px) {
    .basket__list {
        display: grid;
        grid-template-rows: 125px;
        grid-auto-rows: 125px;
        row-gap: 15px;
    }
}
.basket__list {
    display: grid;
    grid-template-rows: 200px;
    grid-auto-rows: 200px;
    row-gap: 30px;
    &__empty {
        color: #fff;
        opacity: 0.6;
        margin-left: 20px;
        font-size: 30px;
    }
}
.shop {
    min-height: 100vh;
    padding-bottom: 200px;
    &__content {
        padding-top: 10px;
        position: relative;
    }
}

@media (max-width: 624px) {
    .shop {
        padding-bottom: 370px;
    }
}
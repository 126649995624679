.product__list {
    display: grid;
    grid-template-columns: repeat(5, 247px);
    grid-template-rows: 323px;
    grid-auto-rows: 323px;
    row-gap: 40px;
    column-gap: 30px;
    margin-top: 50px;
}

@media (max-width: 1351px) {
    .product__list {
        grid-template-columns: repeat(4, 247px);
    }
}

@media (max-width: 1077px) {
    .product__list {
        grid-template-columns: repeat(3, 247px);
    }
}

@media (max-width: 959px) {
    .product__list {
        grid-template-columns: repeat(3, 218px);
        grid-template-rows: 269px;
        grid-auto-rows: 269px;
    }
}

@media (max-width: 713px) {
    .product__list {
        grid-template-columns: repeat(2, 218px);
    }
}

@media (max-width: 687px) {
    .product__list {
        grid-template-columns: repeat(2, 210px);
        grid-template-rows: 255px;
        grid-auto-rows: 252px;
    }
}

@media (max-width: 500px) {
    .product__list {
        grid-template-columns: repeat(2, 190px);
        grid-template-rows: 226px;
        column-gap: 10px;
        row-gap: 70px;
    }
}

@media (max-width: 399px) {
    .product__list {
        grid-template-columns: repeat(2, 153px);
        column-gap: 5px;
        row-gap: 15px;
    }
}
.product__page {
    padding-top: 120px;
    padding-bottom: 220px;
    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 70px;
    }
    &__img {
        width: 650px;
        height: 490px;
        border-radius: 20px;
        box-shadow: 15px 15px 30px rgba($color: #000000, $alpha: 0.4);
        object-fit: cover;
        cursor: pointer;
        opacity: 0;
        transition: all .5s;
        &.active {
            opacity: 1;
            transition: all .5s;
        }
    }
    &__about {
        &__wrapper {
            position: relative;
            display: flex;
            width: 660px;
            min-height: 447px;
            flex-direction: column;
            padding-bottom: 20px;
        }
    }
    &__name {
        color: #fff;
        font-size: 72px;
        font-weight: 300;
        line-height: 72px;
        margin: 0;
        margin-bottom: 7px;
    }
    &__type {
        display: flex;
        color: rgba($color: #F4F4F4, $alpha: .6);
        font-weight: 300;
        margin: 0;
        margin-bottom: 15px;
        span {
            display: flex;
            margin-left: 12px;
            svg {
                fill: #F4F4F4;
            }
        }
    }
    &__price {
        color: #F4F4F4;
        font-size: 54px;
        font-weight: 300;
        margin: 0;
        &-old {
            position: absolute;
            color: rgba($color: #fff, $alpha: 0.55);
            font-weight: 300;
            font-size: 33px;
            top: -26px;
            left: 80px;
            text-decoration: line-through;
            text-decoration-color: #C82A2A;
            margin: 0;
        }
    }
    &__info {
        display: flex;
        flex-wrap: wrap;
        color: #fff;
        font-size: 37px;
        font-weight: 300;
        margin: 0;
        margin-bottom: 13px;
        &__item {
            margin-right: 8px;
        }
        &-descr {
            color: rgba($color: #F4F4F4, $alpha: .6);
            font-size: 30px;
            margin-top: 7px;
        }
        span {
            display: flex;
            margin-left: 12px;
            cursor: pointer;
            svg {
                fill: #F4F4F4;
            }
        }
        &__delete {
            width: 30px;
            margin-left: 3px;
            cursor: pointer;
        }
        &__add {
            margin-top: 0px;
            padding: 0;
            color: #F4F4F4;
            width: 155px;
            height: 30px;
            font-size: 24px;
            border-radius: 30px;
            border: none;
            background-color: rgba($color: #000000, $alpha: 0);
            text-decoration: underline;
            cursor: pointer;
        }
    }
    &__buy {
        &__wrapper {
            position: absolute;
            bottom: -70px;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }
        &__button {
            width: 315px;
            height: 73px;
            font-size: 40px;
            border: none;
            border-radius: 55px;
            background-color: #C82A2A;
            color: #F4F4F4;
            transition: all 0.5s;
            &:hover {
                background-color: #A02222;
            }
        }
    }

}
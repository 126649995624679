@media (max-width: 580px) {
    .create__slider__page__modal {
        &__content {
            width: 400px;
        }
    }
}

@media (max-width: 470px) {
    .create__slider__page__modal {
        &__content {
            width: 350px;
        }
    }
}

@media (max-width: 380px) {
    .create__slider__page__modal {
        &__content {
            width: 90%;
        }
        &__form {
            &__input {
                width: 100%;
                margin-top: 10px;
            }
        }
    }
}

@media (max-width: 340px) {
    .create__slider__page__modal {
        &__content {
            width: 90%;
            height: 470px;
        }
        &__form {
            &__input {
                width: 100%;
                margin-top: 10px;
            }
        }
    }
}


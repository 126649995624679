
@media (max-width: 800px) {
    .auth {
        &__or__reg {
            a {
                font-size: 18px;
            }
        }
        &__content {
            width: 600px;
            min-height: 350px;
            padding-bottom: 20px;
        }
        &__form {
            width: 400px;
            &__input {
                width: 400px;
                margin: 0 auto;
                &.name {
                    margin-bottom: 15px;
                }
            }
            &__password-wrapper {
                width: 400px;
            }
            &__wrapper {
                &__policy {
                    width: 400px;
                }
            }
            &__checkbox{
                &__wrapper {
                    width: 400px;
                }
            }
            &__policy {
                position: relative;
                width: 400px;
            }
        }
        &__btn {
            width: 400px;
            height: 60px;
            font-size: 24px;
            margin-top: 20px;
        }
    }
}

@media (max-width: 650px) {
    .auth {
        &__content {
            width: 430px;
            min-height: 350px;
        }
        &__form {
            width: 340px;
            &__input {
                width: 340px;
                margin: 0 auto;
                &.name {
                    margin-bottom: 15px;
                }
            }
            &__password-wrapper {
                width: 340px;
            }
            &__wrapper {
                &__policy {
                    width: 340px;
                }
            }
            &__checkbox{
                &__wrapper {
                    width: 340px;
                }
            }
            &__policy {
                position: relative;
                width: 340px;
            }
        }
        &__btn {
            width: 340px;
            height: 60px;
            font-size: 24px;
            margin-top: 20px;
        }
    }
}

@media (max-width: 624px) {
    .auth {
        padding-bottom: 370px;
    }
}

@media (max-width: 450px) {
    .auth {
        &__content {
            width: 90%;
            min-height: 350px;
            padding-bottom: 15px;
        }
        &__form {
            width: 300px;
            &__input {
                width: 300px;
                height: 45px;
                margin: 0 auto;
                &.name {
                    margin-bottom: 15px;
                }
            }
            &__password-wrapper {
                width: 300px;
            }
            &__wrapper {
                &__policy {
                    width: 300px;
                    margin-left: 0;
                }
            }
            &__checkbox {
                left: 4px;
                &__wrapper {
                    width: 200px;
                }
            }
            &__policy {
                position: relative;
                width: 300px;
                font-size: 16px;
                margin-left: 0;
            }
            &__password {
                &__show {
                    margin-bottom: 12px;
                    transform: translateY(-2px);
                }
                &__hide {
                    margin-bottom: 10px;
                    transform: translateY(-2px);
                }
            }
        }
        &__btn {
            width: 300px;
            height: 60px;
            font-size: 24px;
            margin-top: 20px;
        }
    }
}

@media (max-width: 450px) {
    .auth {
        &__content {
            min-width: 320px;
        }
        &__btn {
            width: 255px;
            height: 50px;
            font-size: 20px;
            margin-top: 20px;
        }
    }
}

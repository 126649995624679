.promo__slider {
    overflow: hidden;
    width: 100%;
    display: block;
    position: relative;
    min-height: 250px;
    .slick-dots {
        bottom: 10px;
        z-index: 10;
        button {
            &::before {
                color: #fff;
                opacity: .4;
                font-size: 6px;
            }
        }
        .slick-active {
            button {
                &::before {
                    color: #C82A2A;
                }
            }
        }
    }
    &__link {
        position: relative;
    }
    &__edit {
        &__img {
            background-color: rgba($color: #878787, $alpha: .6);
            border-radius: 5px;
            display: block;
            position: absolute;
            padding-bottom: 23px;
            padding-right: 23px;
            width: 22px;
            height: 22px;
            top: 120px;
            right: 202px;
            z-index: 11;
            cursor: pointer;
            svg {
                fill: #F4F4F4;
                margin-left: 0;
            }
        }
        &__link {
            background-color: rgba($color: #878787, $alpha: .6);
            border-radius: 5px;
            display: block;
            position: absolute;
            padding-bottom: 23px;
            padding-right: 23px;
            padding-left: 0.5px;
            width: 22px;
            height: 22px;
            top: 121px;
            right: 177px;
            z-index: 11;
            cursor: pointer;
            svg {
                fill: #F4F4F4;
                margin-bottom: 0px;
                margin-left: 2px;
            }
        }
        &__basket {
            background-color: rgba($color: #878787, $alpha: .6);
            border-radius: 5px;
            display: block;
            position: absolute;
            padding-bottom: 23px;
            padding-right: 23px;
            padding-left: 0.5px;
            width: 22px;
            height: 22px;
            top: 119px;
            right: 151px;
            z-index: 11;
            cursor: pointer;
            svg {
                fill: #F4F4F4;
                margin-bottom: 10px;
            }
        }
    }
    &__img {
        position: relative;
        width: 100%;
        object-fit: cover;
        opacity: 0;
        transition: all .5s;
        &.active {
            opacity: 1;
            transition: all .5s;
        }
        &.mobile {
            display: none;
            &.active-mob {
                display: none;
            }
        }
    }
    &__arrow {
        &-prev {
            display: flex;
            justify-content: center;
            width: 200px;
            height: 100%;
            position: absolute;
            top: 50%;
            left: -50px;
            transform: translateY(-50%);
            opacity: 0.5;
            z-index: 2;
            cursor: pointer;
            svg {
                width: 80px;
            }
        }
        &-next {
            display: flex;
            justify-content: center;
            width: 200px;
            height: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(180deg);
            z-index: 2;
            right: -50px;
            opacity: 0.5;
            cursor: pointer;
            svg {
                width: 80px;
            }
        }
    }
}

